@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

:root {
    --font-family: "Poppins", sans-serif;
    --very-dark-gray: hsl(0, 0%, 17%);
    --dark-gray: hsl(0, 0%, 59%);
}

*, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: var(--font-family);
}

.search_container {
    width: 100%;
    height: 32vh;
    background-image: url("../images/pattern-bg.png");
    background-color: hsl(228, 45%, 44%);
    background-size: cover;
    background-repeat: no-repeat;
}

.search_heading {
    font-size: 1.8rem;
    font-weight: 500;
    color: white;
    text-align: center;
    padding-top: 1.5rem;
}

.form_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 1.5rem;
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.452);
    width: 80%;
    max-width: 30rem;
    border-radius: 20px;
}

.form_container input {
    padding: 1.2rem 1.5rem;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border: none;
    outline: none;
}

.form_container input {
    font-size: 1.1rem;
    color: var(--dark-gray);
    width: 100%;
} 

.input_btn {
    height: 60px;
    width: 60px;
    outline: none;
    border: none;
    background-color: rgb(19, 19, 19);
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: .3s ease;
    cursor: pointer;
}

.input_btn:hover {
    background-color: rgb(68, 68, 68);
}

.info_container {
    position: absolute;
    top: 28%;  
    left: 50%; 
    transform: translate(-50%, -28%);

    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 2rem;
    background-color: white;
    width: 80%;
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    z-index: 2;
}

.info_lists {
    color: var(--dark-gray);
    display: flex;
    gap: 2rem;
    list-style: none;
    font-size: .8rem;
    font-weight: 600;
    letter-spacing: 2px;
}

.info_list {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    gap: .5rem;
    flex-direction: column;
}

.info_list::after {
    position: absolute;
    content: "";
    top: 15%;
    right: 0;
    width: 1px;
    height: 70px;
    background-color: rgb(201, 201, 201);
}

.info_list:last-child::after { display: none; }

.info_header {
    font-size: .8rem;
    letter-spacing: 2px;
    color: var(--dark-gray);
    text-transform: uppercase;
}

.info_content {
    width: 100%;
    font-weight: bold;
    color: var(--very-dark-gray);
    font-size: 1.4rem;
}

.map_container {
    position: relative;
    width: 100%;
    height: 68vh;
    background-color: white;
    z-index: 1;
}

@media screen and (max-width: 860px){
    .info_container {
        margin-top: 2rem;
        padding: .8rem 1rem;
    }

    .info_lists {
        flex-direction: column;
        gap: 1rem;
    }

    .info_list {
        align-items: center;
    }

    .info_content {
        text-align: center;
        font-size: 1.1rem;
    }
    
    .info_list::after {
        display: none;
    }

    .form_container input {
        padding: 1.2rem .8rem 1.2rem 1rem;
    }
}

@media screen and (max-width: 270px){
    .info_container {
        margin-top: 4rem;
    }
}